import { render, staticRenderFns } from "./CompanyPVReceipts.vue?vue&type=template&id=cb20b880&scoped=true&"
import script from "./CompanyPVReceipts.vue?vue&type=script&lang=js&"
export * from "./CompanyPVReceipts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb20b880",
  null
  
)

export default component.exports