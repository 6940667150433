import axios from 'axios';

let logoutUserCall = false;

export default {
  // Consumer
  getUser: () => {
    return axios.get('/api/users')
      .then(response => ({
        success: true,
        user: response.data.user != null
          ? response.data.user : {},
        version: response.data.version,
        language: response.data.language,
      }))
      .catch(error => {
        const { status, data } = error.response;
        const { message, version, language } = data;
        return { success: false, message, version, language, status };
    });
  },

  getNarPV: (pvId) => {
    return axios.get(`/api/pv/nar/activations/${pvId}`)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }))
      .catch(() => {
        return false
      });
  },

  getUserPV: ({ pvId, phoneNr }) => {
    return axios.get(`/api/pv/activations/${pvId}/phone/${phoneNr}`)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
        receipts: response.data.receipts != null
          ? response.data.receipts : [],
        canAddReceipt: response.data.canAddReceipt != null
          ? response.data.canAddReceipt : false,
      }))
      .catch(() => {
        return false
      });
  },

  getUserPV_OLD: (pvId) => {
    return axios.get(`/api/users/pv/${pvId}`)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
        activation: response.data.activation != null
          ? response.data.activation : {},
        receipts: response.data.receipts != null
          ? response.data.receipts : [],
        canAddReceipt: response.data.canAddReceipt != null
          ? response.data.canAddReceipt : false,
        token: response.data.token != null
          ? response.data.token : {},
      }))
      .catch(() => {
        return false
      });
  },

  /*saveUserReceiptMetadata: (payload) => {
    return axios.post(`/api/users/pv/${payload.pvId}/receipt/metadata`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  saveUserReceiptPicture: (payload) => {
    return axios.put(`/api/users/pv/${payload.pvId}/receipt/picture`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },*/

  addUserGoodiebag: (data) => {
    return axios.post(`/api/users/goodiebags`, data)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  setFavoriteOffer: (data) => {
    return axios.post(`/api/users/offers`, data)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  activateOffer: (payload) => {
    return axios.put(`/api/users/offers/${payload.offerId}`, payload)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
        interleaved_code: response.data.interleaved_code != null
          ? response.data.interleaved_code : false,
        ean_link: response.data.ean_link != null
          ? response.data.ean_link : false,
        winner_adress: response.data.winner_adress != null
          ? response.data.winner_adress : false,
        cta_email: response.data.cta_email != null
          ? response.data.cta_email : false,
        no_cta: response.data.no_cta != null
          ? response.data.no_cta : false,
        success: response.data.success != null
          ? response.data.success : undefined,
      }));
  },

  userSurveyUpload: (payload) => {
    return axios.put(`/api/offers/${payload.entityId}/upload`, payload.data, payload.config)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  userActivationSurveyUpload: (payload) => {
    return axios.put(`/api/pv/receipts/${payload.entityId}/survey/upload`, payload.data, payload.config)
      .then(response => ({
        receipt: response.data.receipt != null
          ? response.data.receipt : {},
      }));
  },

  activateHomeOffer: (payload) => {
    return axios.put(`/api/nar/offers/${payload.offerId}`, payload).then(response => ({
        message: response.data.message != null
          ? response.data.message : undefined,
      }));
  },

  donateOffer: (payload) => {
    return axios.put(`/api/offers/${payload.offerId}/donate`).then(response => ({
      user: response.data.user != null
        ? response.data.user : {},
    }));
  },

  changeUser: (payload) => {
    return axios.put('/api/users', payload).then(response => ({
      user: response.data.user != null
        ? response.data.user : undefined,
    }));
  },

  changeEmail: (payload) => {
    return axios.put('/api/users/email', payload).then(response => ({
      user: response.data.user != null
        ? response.data.user : undefined,
    }));
  },

  deleteUserDevice: (payload) => {
    return axios.delete(`/api/users/devices/${payload.deviceId}`).then(response => ({
      user: response.data.user != null
        ? response.data.user : undefined,
    }));
  },

  subscribeEmail: () => {
    return axios.put('/api/users/subscribe')
    .then((response) => ({
      user: response.data.user != null
        ? response.data.user : undefined,
      message: response.data.message != null
      ? response.data.message : undefined,
    }));
  },
  
  subscribeParentCompany: (payload) => {
    return axios.put(`/api/parent-companies/${payload.parentCompanyId}/subscribe`)
    .then((response) => ({
      user: response.data.user != null
        ? response.data.user : undefined,
      message: response.data.message != null
      ? response.data.message : undefined,
    }));
  },

  acceptOfferTerms: (payload) => {
    return axios.put(`/api/offers/${payload.offerId}/terms`, payload)
    .then((response) => ({
      user: response.data.user != null
        ? response.data.user : undefined,
      message: response.data.message != null
      ? response.data.message : undefined,
    }));
  },

  deleteUser: () => {
    return axios.delete(`/api/users`);
  },

  signupLoginUser: (data) => {
    return axios.post('/api/nar/device/email', data)
      .then(response => ({
        success: true,
        newUser: response.data.newUser,
        user: response.data.user != null
          ? response.data.user : {},
      }))
      .catch(err => {
        const { message, error } = err.response.data;
        return { success: false, message, error };
      });
  },

  loginUser: (data) => {
    return axios.post('/api/nar/device', data)
      .then(response => ({
        success: true,
        newUser: response.data.newUser,
        user: response.data.user != null
          ? response.data.user : {},
      }))
      .catch(err => {
        const { message, error } = err.response.data;
        return { success: false, message, error };
      });
  },

  logoutUser: () => {
    if (logoutUserCall) {
      return new Promise((resolve, reject) => { reject('noop') });
    }
    logoutUserCall = true;

    return axios.put('/api/nar/logout')
      .then((response) => {
        logoutUserCall = false;
        return {
          message: response.data.message != null
            ? response.data.message : {},
        };
      });
  },

  // Admin
  bookmarkAdminGoodiebag: (payload) => {
    return axios.put(`/api/admin/goodiebags/${payload.entityId}/bookmark`, payload)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  bookmarkAdminOffer: (payload) => {
    return axios.put(`/api/admin/offers/${payload.entityId}/bookmark`, payload)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  // Company
  bookmarkCompanyGoodiebag: (payload) => {
    return axios.put(`/api/c/goodiebags/${payload.entityId}/bookmark`, payload)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  bookmarkCompanyOffer: (payload) => {
    return axios.put(`/api/c/offers/${payload.entityId}/bookmark`, payload)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },
  getMyProducts: (companyId) => {
    return axios.get(`/api/pv/companies/${companyId}/myreceipts`)
      .then((response) => {
        return {
          receipts: response.data.receipts != null
            ? response.data.receipts : [],
        };
      });
  },
};
