<template>
  <div>
    <ReceiptList :admin="false"/>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import ReceiptList from '../stories/components/ReceiptList/ReceiptList.vue';

export default {
  name: "CompanyPVReceipts",
  mixins: [BeforeEnterMixin],
  components: {
    ReceiptList,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
